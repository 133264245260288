import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMarketDataUrl, getOrderAlert } from "../../redux/auth/authSlice";

const Market = () => {
  const dispatch = useDispatch();
  const { marketData, orderAlertData } = useSelector((state) => state?.counter);
  console.log(orderAlertData,"orderAlertData");

  useEffect(() => {
    dispatch(getMarketDataUrl(dispatch));
    dispatch(getOrderAlert(dispatch));
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "12px 20px",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "320px",
          margin: "20px auto",
          transition: "transform 0.3s ease",
          ":hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#444",
            marginRight: "12px",
          }}
        >
          View Historical Data:
        </span>
        <button
          style={{
            padding: "5px 25px",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#28a745",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease, transform 0.3s ease",
            ":hover": {
              backgroundColor: "#218838",
              transform: "translateY(-2px)",
            },
            ":active": {
              backgroundColor: "#1e7e34",
            },
          }}
          onClick={() => window.open(marketData?.nb_url, "_blank")}
        >
          View
        </button>
      </div>
      <div className="order-alerts-container">
      {/* <h1 className="order-a">Order Alerts</h1> */}
      <div className="order-alerts-table-wrapper">
        <table className="order-alerts-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Broker Name</th>
              <th>Date</th>
              <th>Trading Symbol</th>
              <th>Success</th>
              <th>Transaction Type</th>
              <th>Strategy</th>
              <th>Alert Type</th>
            </tr>
          </thead>
          <tbody>
            {orderAlertData?.map((alert) => (
              <tr key={alert?.id}>
                <td>{alert?.id}</td>
                <td>{alert?.broker_name}</td>
                <td>{new Date(alert?.date)?.toLocaleString()}</td>
                <td>{alert?.trading_symbol}</td>
                <td>{alert?.is_success ? 'Yes' : 'No'}</td>
                <td>{alert?.transaction_type}</td>
                <td>{alert?.strategy}</td>
                <td>{alert?.alert_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default Market;
