import React from 'react'

const Holdings = () => {
    return (

        <>
            <div>
                <div className="mt-3">
                    <div className="card text-white mb-3 holdcard" style={{ backgroundColor: '#142f54' }}>
                        <div className="card-body">
                            <h3 className="card-title">₹1,066</h3>
                            <p className="card-text">
                                <span className="text-success">↑ Overall Gain ₹20.24 (+1.93%)</span><br />
                                Invested Value ₹1,045<br />
                                <span className="text-success">↑ Today's Gain ₹2.53 (+0.23%)</span>
                            </p>
                        </div>
                    </div>
                    {/* <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Search for a company or a stock" aria-label="Search" />
        <button className="btn btn-outline-secondary" type="button"><i className="bi bi-funnel"></i></button>
      </div> */}
                    <div className='col-lg-12' style={{ overflowX: 'auto' }}>
                        <table className="table holdcard">
                            <thead>
                                <tr>
                                    <th>Stock</th>
                                    <th>Price Change</th>
                                    <th>Average Price</th>
                                    <th>Shares</th>
                                    <th>Invested</th>
                                    <th>LTP</th>
                                    <th>Today's Gain</th>
                                    <th>Current Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>RENUKA</td>
                                    <td className="text-success">₹20.24 (+1.93%)</td>
                                    <td>₹45.46</td>
                                    <td>23</td>
                                    <td>₹1,045</td>
                                    <td>₹46.34 (+0.24%)</td>
                                    <td>₹2.53 (+0.24%)</td>
                                    <td>₹1,066</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Holdings;