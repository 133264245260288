import React from "react";
import "../../App.css";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import Logform from "../Component/Logform";


const Login = () => {
  const { isLoading } = useSelector((state) => state?.counter);


  return (
    <>
      {isLoading && <Loader />}
      <section className="background-radial-gradient overflow-hidden">
        <div className="container px-4 py-3 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-lg-0" style={{ zIndex: "10" }}>
              <h1
                className="my-5 display-5 fw-bold ls-tight"
                style={{ color: "hsl(218, 81%, 95%)" }}
              >
                <br />
                <span style={{ color: "hsl(218, 81%, 75%)", fontSize: "60px", fontFamily: 'serif' }}>
                  Q-Star
                </span>
              </h1>
              <p
                className="mb-4 opacity-70"
                style={{ color: "hsl(218, 81%, 85%)" }}
              >

              </p>
            </div>
            <Logform />
          </div>
        </div>
      </section>

    </>
  );
};

export default Login;
