import React, {useState } from "react";
import { Toaster } from "react-hot-toast";
import { BsFillShieldLockFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import OtpInput from "react-otp-input";
import { Otpverify } from "../../redux/auth/authSlice";

const VerifyOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.counter);
  const [otp, setOtp] = useState("");

  // useEffect(() => {
  //   let obj = {
  //     email: sessionStorage?.getItem("email"),
  //     reference_number: sessionStorage?.getItem("reference_number"),
  //   };
  //   // dispatch(RequestEmailOtp({ obj, dispatch }));
  // }, [ dispatch]);

  function onOtpverify() {
    let obj = {
      email: localStorage?.getItem("email"),
      otp: otp,
    };
    dispatch(Otpverify({ obj, navigate, dispatch }));
  }

  return (
    <>
      {isLoading && <Loader />}
      <section
        className="background-radial-gradient overflow-hidden"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className={`d-flex justify-content-center`}>
          <Toaster toastOptions={{ duration: 4000 }} />
          <div
            className={`row position-absolute `}
            style={{ marginTop: "100px" }}
          >
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>

            <div
              className="signuppage mt-5 bg-light text-black p-5"
              style={{
                zIndex: "9999",
                borderRadius: "15px",
              }}
            >
              <div className="optvarificationcontent">
                <span className="d-flex justify-content-center">
                  <BsFillShieldLockFill size={40} />
                </span>
                <h6 className="text-center mt-3">Enter Your Email OTP </h6>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  shouldAutoFocus
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "30px",
                        marginRight: "12px",
                        textAlign: "center",
                      }}
                    />
                  )}
                ></OtpInput>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary mt-3 w-75 "
                    onClick={onOtpverify}
                  >
                    <span>Verify OTP</span>
                  </button>
                </div>
              </div>
            </div>

            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyOTP;
