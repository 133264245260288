import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../Components/Loader/Loader";
import { SetEmptycsv, historicaldata, instrumentsdetails, usersettingsget } from '../../redux/auth/authSlice';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const Historydata = () => {
    const dispatch = useDispatch();
    const { isLoading, instrumentsdata, csv_file_url, csv_fileName } = useSelector((state) => state?.counter);
    const { usersettingsdata } = useSelector((state) => state?.counter);

    console.log("usersettingsdata1", usersettingsdata)
    const [error, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState()
    const [onApiHit, setOnApiHit] = useState(false);
    const [historydata, setHistorydata] = useState({
        broker_name: "",
        name: "",
        instrument_token: "",
        from_date: null,
        to_date: null,
        trading_symbol: "",
        interval_time: "",
        exchange: "",
        daily_basis_update: false
    });
    // const statedata = csv_file_url
    // const statename = csv_fileName
    console.log("historydata", historydata)

    const Validation = () => {
        const errors = {};
        if (!historydata?.broker_name) {
            errors.broker_name = "Enter Broker Name*";
        }
        if (!historydata?.exchange) {
            errors.exchange = "Enter Exchange*";
        }
        if (!historydata?.name) {
            errors.name = "Enter Stock*";
        }
        if (!historydata?.interval_time) {
            errors.interval_time = "Enter Interval*";
        }

        // if (!historydata?.from_date) {
        //     errors.from_date = "Enter Start Date*";
        // }
        // if (!historydata?.to_date) {
        //     errors.to_date = "Enter End Date*";
        // }
        // if (historydata?.from_date && historydata?.to_date && new Date(historydata.from_date) > new Date(historydata.to_date)) {
        //     errors.from_date = "Start Date cannot be greater than End Date";
        // }

        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const handleSelectChange = (selectedOption) => {
        if (!selectedOption) return;
        setHistorydata((prevData) => ({
            ...prevData,
            instrument_token: selectedOption.value,
            name: selectedOption.label.split("(")[0].trim(),
            trading_symbol: selectedOption.symb
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            name: undefined,
        }));
    };

    const handleChange = (e) => {
        const { name, value, id, checked } = e.target;
        if (name === 'interval_time' && value === 'minute') {
            setErrorMessage('Zerodha only provide 60days data at 1m interval try with date filters');
        } else {
            setErrorMessage('')
        }
        if (id === "daily_basis_update") {
            setHistorydata((prevData) => ({
                ...prevData,
                [id]: checked,
            }));
        } else {
            setHistorydata((prevData) => ({
                ...prevData,
                [name]: value.trim(),
            }));
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Validation()) {
            return;
        } else {
            setOnApiHit(true);
            try {
                await dispatch(historicaldata({ historydata, setHistorydata, dispatch }));
                setErrors({});
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        }
    };

    useEffect(() => {
        dispatch(SetEmptycsv())
    }, [])

    // useEffect(() => {
    //     if (statedata && statedata.length > 0 && onApiHit) {
    //         const data = statedata
    //         const fileName = statename
    //         const exportType = exportFromJSON.types.csv
    //         exportFromJSON({ data, fileName, exportType })
    //     }
    // }, [statedata, onApiHit])

    const brokername = instrumentsdata?.broker_name;
    // const options = instrumentsdata?.instrument_list?.map((option) => ({
    //     value: option?.instrument_token,
    //     label: `${option?.name} (${option?.trading_symbol})`,
    //     symb: option?.trading_symbol,
    // }));

    const filteredOptions = instrumentsdata?.instrument_list
        ?.filter((option) => option.exchange === historydata.exchange)
        .map((option) => ({
            value: option.instrument_token,
            label: `${option.name} (${option.trading_symbol})`,
            symb: option.trading_symbol,
        }));

    // useEffect(() => {
    //     if (historydata.broker_name && historydata.broker_name) {

    //         dispatch(instrumentsdetails(dispatch));
    //     }
    // }, [historydata.broker_name, usersettingsdata]);

    useEffect(() => {
        dispatch(usersettingsget(dispatch));
    }, [dispatch]);

    useEffect(() => {

        dispatch(instrumentsdetails(dispatch));

    }, [dispatch]);


    return (
        <>
            {/* {isLoading && <Loader />} */}
            {usersettingsdata?.data?.length > 0 ? (<div>
                <section className="profile-section">
                    <form onSubmit={handleSubmit}>
                        <h1 className='form-headings'>Download Historical data</h1>

                        <label htmlFor="exampleInputLastName" className="form-label">Broker Name</label>
                        <select
                            style={{ marginBottom: "10px" }}
                            className="form-control selectpiker"
                            aria-label="Default select example"
                            name="broker_name"
                            value={historydata?.broker_name}
                            onChange={handleChange}
                        >
                            <option value="">Select name</option>
                            <option value={brokername}>{brokername ? brokername.charAt(0).toUpperCase() + brokername.slice(1) : ''}</option>
                        </select>
                        <p style={{ color: "red" }}>{error.broker_name}</p>

                        <label htmlFor="exampleInputLastName" className="form-label">Exchange</label>
                        <select
                            style={{ marginBottom: "10px" }}
                            className="form-control selectpiker"
                            aria-label="Default select example"
                            name="exchange"
                            value={historydata.exchange}
                            onChange={handleChange}
                        >
                            <option value="">Select exchange</option>
                            <option value="BSE">BSE</option>
                            <option value="NSE">NSE</option>
                        </select>
                        <p style={{ color: "red" }}>{error.exchange}</p>

                        <label htmlFor="exampleInputLastName" className="form-label">Stock</label>
                        <Select
                            value={filteredOptions?.find(option => option.value === historydata.instrument_token)}
                            onChange={handleSelectChange}
                            options={filteredOptions}
                            isSearchable={true}
                            placeholder="Select Stock"
                            styles={{ overflow: "auto" }}
                            key={historydata.exchange}
                        />
                        <p style={{ color: "red" }}>{error.name}</p>


                        <label htmlFor="exampleInputLastName" className="form-label">Interval</label>
                        <select
                            style={{ marginBottom: "10px" }}
                            className="form-control selectpiker"
                            aria-label="Default select example"
                            name="interval_time"
                            value={historydata?.interval_time}
                            onChange={handleChange}
                        >
                            <option value="">Select Interval</option>
                            {instrumentsdata?.interval_time?.map((item, i) => (
                                <option key={i} value={item.value}>{item.label}</option>
                            ))}
                        </select>
                        <p style={{ color: "red" }}>{error.interval_time || errorMessage}</p>

                        <label htmlFor="exampleInputStartDate" className="form-label">Start Date</label>
                        <input
                            type="date"
                            name="from_date"
                            className="form-control"
                            id="from_date"
                            value={historydata?.from_date ? historydata?.from_date : ""}
                            onChange={handleChange}
                            max={new Date().toISOString().split('T')[0]}
                        />
                        <p style={{ color: "red" }}>{error?.from_date}</p>

                        <label htmlFor="exampleInputEndDate" className="form-label">End Date</label>
                        <input
                            type="date"
                            className="form-control"
                            id="to_date"
                            name="to_date"
                            value={historydata?.to_date ? historydata?.to_date : ""}
                            onChange={handleChange}
                            max={new Date().toISOString().split('T')[0]}
                        />
                        <p style={{ color: "red" }}>{error?.to_date}</p>

                        <div className='check-box-div'>
                            <Form>
                                <Form.Check
                                    className='form-label'
                                    type="checkbox"
                                    name="daily_basis_update"
                                    id="daily_basis_update"
                                    checked={historydata?.daily_basis_update}
                                    value={historydata?.daily_basis_update ? historydata?.daily_basis_update : false}
                                    onChange={(e) => handleChange(e)}
                                    label="Allow to update data on daily basis"
                                />
                            </Form>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </section>
            </div>) : (
                <div>
                    <div className="container">
                        <div className="historycard col-lg-6">
                            <div className="title">
                                <h1 style={{ color: "#2f4d81", fontWeight: 400, fontSize: "25px" }}>First Add keys</h1>
                                <div>
                                    <Link to="/addkey"> click here</Link>
                                </div>
                            </div>
                            <div className="content">

                            </div>
                        </div>
                    </div>

                </div>
            )}

        </>
    )
};


export default Historydata;