import React from "react";
import Profileform from "../../Components/Component/Profileform";


const Profile = () => {

  return (
    <>
    <Profileform/>
    </>
  )
};

export default Profile;
