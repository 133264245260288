import { Routes, Route } from "react-router-dom";
import Layout from "../../Components/Layout/index";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import OTPVerify from "../../Components/Authentication/VerifyOTP";
import Login from "../../Components/Authentication/Login";
import Register from "../../Components/Authentication/Register";
import Settings from "./Settings";
import Addkey from "./Addkey";
import History from "./History";
import Zerodhasuccess from "./Zerodhasuccess";
import Market from "./Market";
import OrderAlerts from "../../Components/Component/Alert";
import Holdings from "./Holdings";

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/verify-otp" element={<OTPVerify />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/addkey" element={<Addkey />}></Route>
          <Route path="/history" element={<History />}></Route>
          <Route path="/historicaldata" element={<Market />}></Route>
          <Route path="/zerodhasuccess" element={<Zerodhasuccess />}></Route>
          <Route path="/alerts" element={<OrderAlerts />}></Route>
          <Route path="/holdings" element={<Holdings />}></Route>
        </Routes>
      </Layout>
    </>
  );
}

export default App;
