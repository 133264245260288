import React, { useEffect, useState } from "react";
import NavbarSection from "./Navbar";
import Sidebar from "./Sidebar";
import FooterSection from "./Footer";
import { useLocation } from "react-router";

const Layout = (props) => {
  const [toggle, setToggle] = useState(false);
  const { children } = props;
  const location = useLocation();

  const HandleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    document.body.classList.remove("open-class");
    setTimeout(() => {
      if (toggle) {
        document.body.classList.add("open-class");
      }
    }, 200);
  }, [toggle]);

  if (location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/otp" || location.pathname === "/verify-otp" || location.pathname === "/zerodhasuccess") {
    return children;
  }

  return (
    <>
      <NavbarSection HandleToggle={HandleToggle} toggle={toggle} />
      <Sidebar HandleToggle={HandleToggle} toggle={toggle} />
      <section className="dasboard-all pages">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-dasboard">{children}</div>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
    </>
  );
};

export default Layout;
