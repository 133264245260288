import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAlert } from "../../redux/auth/authSlice";

const OrderAlerts = () => {
  const dispatch = useDispatch();
  const { orderAlertData } = useSelector((state) => state?.counter);
  const [successFilter, setSuccessFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [alertsPerPage] = useState(50);
  const [currentPageSet, setCurrentPageSet] = useState(0);

  useEffect(() => {
    dispatch(getOrderAlert(dispatch));
  }, [dispatch]);

  const handleFilterChange = (e) => {
    setSuccessFilter(e.target.value);
    setCurrentPage(1);
    setCurrentPageSet(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
    setCurrentPageSet(0);
  };

  const filteredAlerts = orderAlertData?.filter((alert) => {
    const isSuccessMatch =
      successFilter === "all" ||
      (successFilter === "success" && alert.is_success) ||
      (successFilter === "failure" && !alert.is_success);

    const isSearchMatch = () => {
      if (searchQuery.includes("/")) {
        const formattedDate = new Date(alert.date).toLocaleDateString();
        return formattedDate.includes(searchQuery);
      } else {
        return (
          alert.id.toString().includes(searchQuery) ||
          alert.broker_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          alert.trading_symbol.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    };
    return isSuccessMatch && isSearchMatch();
  });

  // Pagination logic
  const indexOfLastAlert = currentPage * alertsPerPage;
  const indexOfFirstAlert = indexOfLastAlert - alertsPerPage;
  const currentAlerts = filteredAlerts?.slice(indexOfFirstAlert, indexOfLastAlert);

  const totalPages = Math.ceil(filteredAlerts?.length / alertsPerPage);
  const pagesPerSet = 10;
  const totalPageSets = Math.ceil(totalPages / pagesPerSet);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPageSet = () => setCurrentPageSet((prev) => Math.min(prev + 1, totalPageSets - 1));
  const prevPageSet = () => setCurrentPageSet((prev) => Math.max(prev - 1, 0));

  const currentSetStart = currentPageSet * pagesPerSet;
  const currentSetEnd = Math.min(currentSetStart + pagesPerSet, totalPages);

  return (
    <div className="order-alerts-container">
      <div className="order-alerts-table-wrapper">
        <h1 style={{ margin: "22px 0 10px 0" }}>Order Alerts</h1>
        <div className="filter-search-controls">
          <select value={successFilter} onChange={handleFilterChange}>
            <option value="all">All</option>
            <option value="success">Success</option>
            <option value="failure">Failure</option>
          </select>
          <input
            type="text"
            placeholder="Search by ID, Trading Symbol, Broker Name, or Date"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="order-alerts-table-wrapper">
        <table className="order-alerts-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Broker Name</th>
              <th>Date</th>
              <th>Trading Symbol</th>
              <th>Success</th>
              <th>Transaction Type</th>
              <th>Strategy</th>
              <th>Alert Type</th>
              <th>Trade Type</th>
              <th>Exchange</th>
              <th>Order ID</th>
            </tr>
          </thead>
          <tbody>
            {currentAlerts?.length > 0 ? (
              currentAlerts.map((alert) => (
                <tr key={alert?.id}>
                  <td>{alert?.id}</td>
                  <td>{alert?.broker_name}</td>
                  <td>{new Date(alert.date).toLocaleString()}</td>
                  <td>{alert?.trading_symbol}</td>
                  <td>{alert?.is_success ? "Yes" : "No"}</td>
                  <td>{alert?.transaction_type}</td>
                  <td>{alert?.strategy}</td>
                  <td>{alert?.alert_type}</td>
                  <td>{alert?.type}</td>
                  <td>{alert?.exchange}</td>
                  <td>{alert?.order_id}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {filteredAlerts?.length > 0 && (
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPageSet === 0 ? "disabled" : ""}`}>
                <button className="page-link" onClick={prevPageSet} disabled={currentPageSet === 0}>
                  &laquo; Prev
                </button>
              </li>
              {Array.from({ length: currentSetEnd - currentSetStart }, (_, index) => (
                <li
                  key={index + 1 + currentSetStart}
                  className={`page-item ${index + 1 + currentSetStart === currentPage ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(index + 1 + currentSetStart)}
                  >
                    {index + 1 + currentSetStart}
                  </button>
                </li>
              ))}
              <li className={`page-item ${currentPageSet === totalPageSets - 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={nextPageSet}
                  disabled={currentPageSet === totalPageSets - 1}
                >
                  Next &raquo;
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default OrderAlerts;
