import "./App.css";
import UserRoutes from "./Pages/User-panel/Route";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {

    return (
      <>
        <Router>
          <Routes>
            <Route path="/*" element={<UserRoutes />}></Route>
          </Routes>
        </Router>
      </>
    );
    }

export default App;
