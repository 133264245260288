import React from "react";
import "../App.css";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import Loader from "./Loader/Loader";
import { googlelogin } from "../redux/auth/authSlice";
import { jwtDecode } from "jwt-decode";


const GoogleAuthentication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { isLoading } = useSelector((state) => state?.counter);


  return (
    <>
      <div className="center">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const response = jwtDecode(credentialResponse.credential)
            // console.log("credentialResponsecredentialResponse",response)
            if (credentialResponse?.clientId) {
              // let data = {
              //   idToken: credentialResponse?.credential,
              // };
              let logindata = {
                email: response?.email,
                first_name: response?.given_name,
                last_name: response?.family_name
              }
              dispatch(googlelogin({ logindata, navigate, dispatch }));
            }
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap
        />
      </div>
    </>
  );
};

export default GoogleAuthentication;
