import React from "react";
const FooterSection = () => {
  return (
    <>

    </>
  );
};

export default FooterSection;
