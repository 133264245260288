import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import GoogleAuthentication from "../GoogleAuthentication";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../redux/auth/authSlice";
import VerifyOTP from "../Authentication/VerifyOTP";



const Logform = () => {
  const [error, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const Validation = () => {
    const errors = {};
    if (loginData?.email === "" && loginData?.email?.length === 0) {
      errors.email = "Enter Email*";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginData?.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (loginData?.password === "" && loginData?.password?.length === 0) {
      errors.password = "Enter Password*";
    }
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const HandleChange = (e) => {
    setLoginData({
      ...loginData, [e.target.name]: e.target.value.trim(),
    });
    setErrors({ ...error, [e.target.name]: undefined });
  };

  const HandleLogin = (e) => {
    e?.preventDefault();
    let data = Validation();
    if (data) {
      return;
    } else {
      dispatch(LoginUser({ setShow, loginData, navigate, dispatch }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
        <div
          id="radius-shape-1"
          className="position-absolute rounded-circle shadow-5-strong"
        ></div>
        <div
          id="radius-shape-2"
          className="position-absolute shadow-5-strong"
        ></div>
        <div className="card bg-glass">
          <div className="card-body px-4 py-3 px-md-5">
            <form>
              <div className="form-outline mb-4 Label_css">
                <label className="form-label" htmlFor="form3Example3">
                  <p style={{ fontSize: "30px" }}>Login</p>
                </label>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Email address
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={loginData.email}
                  onChange={(e) => HandleChange(e)}
                />
                <p style={{ color: "red" }}>{error.email}</p>
              </div>
              <div className="form-outline mb-4" style={{ position: "relative" }}>
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control"
                  onChange={(e) => HandleChange(e)}
                />
                <div className="pass-toggle">
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={togglePasswordVisibility} />
                </div>
                <p style={{ color: "red" }}>{error.password}</p>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block mb-4 btn-100"
                onClick={(e) => HandleLogin(e)}
              >
                Sign in
              </button>
              <div className="text-center">
                <p>
                  Don't have an account ? <Link to="/register" style={{ textDecoration: "none" }}>Sign up</Link>
                </p>
                <p>or sign up with</p>
                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <GoogleAuthentication />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="auth-modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><VerifyOTP /></Modal.Body>
      </Modal>
    </>
  )
}

export default Logform;