import React from "react";
import { useNavigate } from "react-router";
// import { LogoutHandler } from "../../redux/auth/authSlice";

const Navbar = ({ HandleToggle }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const HandleLogin = (item) => {
    navigate(item);
  };

  // const emailData = sessionStorage?.getItem("email");

  const access_token = localStorage?.getItem("Token")

  const HandleLogout = () => {
    localStorage.clear();
    navigate("/login")
  };

  return (
    <>
      <section className="dasboard-all top-header ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-flex">
                <ul>
                  <li>
                    <button onClick={HandleToggle} className="tog-bt">
                      <i className="fa-solid fa-caret-left"></i>
                      <i className="fa-solid fa-bars"></i>
                    </button>
                  </li>
                  <li>
                    {access_token ? (
                      <button className="log-out-bt" onClick={HandleLogout}>
                        {" "}
                        Logout
                      </button>
                    ) : (
                      <button
                        className="log-out-bt"
                        onClick={() => HandleLogin("/login")}
                      >
                        {" "}
                        Login
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
