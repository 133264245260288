import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import { profileget, profilepost } from "../../redux/auth/authSlice";

const Profileform = () => {
  const dispatch = useDispatch();
  const { profilegetdata, isLoading } = useSelector((state) => state.counter);

  const [nameerror, setNameError] = useState("");
  const [lasterror, setLastError] = useState("");
  const [error, setErrors] = useState({});

  const [updatedata, setUpdateData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    email_preference: "",
    sms_preference: "",
    broker_preference: "",
  });

  const Validation = () => {
    const errors = {};
    if (!updatedata.first_name.trim()) {
      errors.first_name = "Enter First Name*";
    }
    if (!updatedata.last_name.trim()) {
      errors.last_name = "Enter Last Name*";
    }
    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const HandleChange = (e) => {
    const { name, value, checked, id } = e.target;

    if (name === 'first_name') {
      const isValid = /^[a-zA-Z0-9 ]*$/.test(value);
      if (!isValid) {
        setNameError('Special characters not allowed');
        return;
      } else {
        setNameError('');
      }
    } else if (name === 'last_name') {
      const isValid = /^[a-zA-Z0-9 ]*$/.test(value);
      if (!isValid) {
        setLastError('Special characters not allowed');
        return;
      } else {
        setLastError('');
      }
    }

    if (id === "email_preference" || id === "sms_preference" || id === "broker_preference") {
      setUpdateData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    }
    else {
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.trim(),
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  useEffect(() => {
    dispatch(profileget(dispatch));
  }, [dispatch]);

  useEffect(() => {
    if (profilegetdata) {
      setUpdateData({
        ...updatedata,
        first_name: profilegetdata.first_name || "",
        last_name: profilegetdata.last_name || "",
        email: profilegetdata.email || "",
        broker_preference: profilegetdata.preference?.broker_preference || false,
        sms_preference: profilegetdata.preference?.sms_preference || false,
        email_preference: profilegetdata.preference?.email_preference || false,
      });
    }
  }, [profilegetdata]);

  const HandleUpdate = (e) => {
    e.preventDefault();
    if (Validation()) {
      return;
    } else {
      dispatch(profilepost({ updatedata, dispatch }));
    }
  };

  return (
    <>
      <div>
        {isLoading && <Loader />}
        <section className="profile-section">
          <h2 className='form-headings'>Profile Update</h2>
          <form onSubmit={HandleUpdate}>
            <div className="mb-3">
              <label htmlFor="exampleInputFirstName" className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={updatedata.first_name}
                id="exampleInputFirstName"
                placeholder="First Name"
                onChange={HandleChange}
              />
              <p style={{ color: "red" }}>{error.first_name}</p>
              <p style={{ color: "red" }}>{nameerror}</p>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputLastName" className="form-label">Last Name</label>
              <input
                type="text"
                name="last_name"
                className="form-control"
                id="exampleInputLastName"
                value={updatedata.last_name}
                placeholder="Last Name"
                onChange={HandleChange}
              />
              <p style={{ color: "red" }}>{error.last_name}</p>
              <p style={{ color: "red" }}>{lasterror}</p>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
              <input
                disabled
                type="email"
                className="form-control"
                name="email"
                value={updatedata.email}
                id="exampleInputEmail1"
                placeholder="Email"
                aria-describedby="emailHelp"
                onChange={HandleChange}
              />
              <p style={{ color: "red" }}>{error.email}</p>
            </div>

            <div className="mb-3">
              <div className="switch-div">
                <Form className="switch-form">
                  <Form.Check
                    reverse
                    label="Email"
                    id="email_preference"
                    type="switch"
                    checked={updatedata.email_preference}
                    onChange={HandleChange}
                  />
                  <Form.Check
                    reverse
                    label="SMS"
                    id="sms_preference"
                    type="switch"
                    checked={updatedata.sms_preference}
                    onChange={HandleChange}
                  />
                  <Form.Check
                    reverse
                    label="Broker Name"
                    id="broker_preference"
                    type="switch"
                    checked={updatedata.broker_preference}
                    onChange={HandleChange}
                  />
                </Form>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">Update</button>
          </form>
        </section>
      </div>
    </>
  );
};


export default Profileform