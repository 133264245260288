import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from 'react-redux'
import { store } from "./redux/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 
    <Provider store={store}>
    <GoogleOAuthProvider clientId="653220918951-2gmhmusmsrnjfgcpo70g17t5ghr2j32o.apps.googleusercontent.com">
    <ToastContainer />
      <App/>
    </GoogleOAuthProvider>
    </Provider>
 
);

reportWebVitals();
