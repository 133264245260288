import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersettingsget, usersettingspost } from '../../redux/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import Loader from "../../Components/Loader/Loader";


const Addkey = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, usersettingsdata } = useSelector((state) => state?.counter);
    const [error, setErrors] = useState({});
    const [formDataa, setFormDataa] = useState({
        broker_name: '',
        api_secret: '',
        api_key: '',
        broker_user_id: ''
    });

    const Validation = () => {
        const errors = {};
        if (formDataa?.broker_name === "" && formDataa?.broker_name?.length === 0) {
            errors.broker_name = "Enter Broker Name*";
        } if (formDataa?.api_secret === "" && formDataa?.api_secret?.length === 0) {
            errors.api_secret = "Enter Secret Name*";
        }
        if (formDataa?.api_key === "" && formDataa?.api_key?.length === 0) {
            errors.api_key = "Enter Access Key*";
        }
        if (formDataa?.broker_user_id === "" && formDataa?.broker_user_id?.length === 0) {
            errors.broker_user_id = "Enter Access Key*";
        }
        setErrors(errors);
        const hasErrors = Object.keys(errors).length > 0;
        if (hasErrors) {
            return true;
        } else {
            return false;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDataa({ ...formDataa, [name]: value.trim() });
        setErrors({ ...error, [e.target.name]: undefined });

    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        let data = Validation();
        if (data) {
            return;
        } else {
            dispatch(usersettingspost({ formDataa, dispatch, navigate }))
        }
    };

    useEffect(() => {
        dispatch(usersettingsget(dispatch));
    }, [dispatch]);

    const brokerName1 = (usersettingsdata?.data?.find((item) => item?.broker_name === "icici")?.broker_name || "").replace(/^\w/, (c) => c.toUpperCase());
    const brokerName2 = (usersettingsdata?.data?.find((item) => item?.broker_name === "zerodha")?.broker_name || "").replace(/^\w/, (c) => c.toUpperCase());


    // useEffect(() => {
    //     const brokerName = usersettingsdata?.find((item) => item?.broker_name === formDataa?.broker_name);

    //     if (brokerName) {
    //         setFormDataa({
    //             broker_name: brokerName?.broker_name,
    //             api_secret: brokerName?.api_secret,
    //             api_key: brokerName?.api_key,
    //             broker_user_id: brokerName?.broker_user_id
    //         });
    //         setErrors({});
    //     }
    // }, [formDataa.broker_name, usersettingsdata]);

    return (
        <>
            {isLoading && <Loader />}

            <Link to="/settings" >
                <button className='add-key-btn'><i className="fa-solid fa-left-long"></i> Back</button>
            </Link>
            <div>
                <section className="profile-section">
                    <form onSubmit={handleSubmit}>
                        <h1 className='form-headings'>Add New Key</h1>
                        <select
                            style={{ marginBottom: "10px" }}
                            className="form-select"
                            aria-label="Default select example"
                            name="broker_name"
                            value={formDataa.broker_name}
                            onChange={handleChange}
                        >
                            <option value="">Select broker</option>
                            {!brokerName2 && <option value="zerodha">Zerodha</option>}
                            {!brokerName1 && <option value="icici">Icici</option>}
                        </select>
                        <p style={{ color: "red" }}>{error.broker_name}</p>

                        <div className="mb-3">
                            <label htmlFor="broker_user_id" className="form-label">Broker Id</label>
                            <input type="text" className="form-control" id="broker_user_id" name="broker_user_id" placeholder='Enter your Broker user id' value={formDataa.broker_user_id}
                                onChange={handleChange} />
                            <p style={{ color: "red" }}>{error.broker_user_id}</p>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="api_key" className="form-label">Api Key</label>
                            <input type="text" className="form-control" id="api_ke
                            y" name="api_key" placeholder='Enter your Api key' value={formDataa.api_key}
                                onChange={handleChange} />
                            <p style={{ color: "red" }}>{error.api_key}</p>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="secret_key" className="form-label">Secret Key</label>
                            <input type="text" name="api_secret" className="form-control" id="secret_key" aria-describedby="emailHelp" placeholder='Enter your Secrect key' value={formDataa.api_secret}
                                onChange={handleChange} />
                            <p style={{ color: "red" }}>{error.api_secret}</p>

                        </div>


                        <button type="submit" className="btn btn-primary">Add key</button>
                    </form>
                </section>
            </div>
        </>
    )
}

export default Addkey;