import React from 'react'
import "../../App.css";
import { FadeLoader } from 'react-spinners';


const Loader = () => {
return (
    <div className="loading-screen">
        <FadeLoader color="#123abc" height={15} width={5} />
    </div>
    );
}

export default Loader