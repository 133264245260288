import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const Base_url = "https://api.qstar-web.com/api/";
const initialState = {
  googleSigninData: [],
  usersettingsdata: [],
  profilegetdata: {},
  instrumentsdata: [],
  zerodhaAccessToken: "",
  isLoading: false,
  csv_file_url: [],
  csvName: "",
  marketData: {},
  orderAlertData: [],
  strategyInfoData: [],
};

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    SetLoading: (state, actions) => {
      state.isLoading = actions.payload;
    },
    SetEmptycsv: (state) => {
      state.csv_file_url = [];
      state.csvName = "";
    },
    brokerlistdata: (state, actions) => {
      state.usersettingsdata = actions.payload;
    },
    profiledata: (state, actions) => {
      state.profilegetdata = actions.payload;
    },
    instruments: (state, actions) => {
      state.instrumentsdata = actions.payload;
    },
    zerodhaAccsess: (state, actions) => {
      state.zerodhaAccessToken = actions.payload;
    },
    csvDownload: (state, actions) => {
      state.csv_file_url = actions.payload;
    },
    csvName: (state, actions) => {
      state.csv_fileName = actions.payload;
    },
    saveMarketData: (state, actions) => {
      state.marketData = actions.payload;
    },
    saveOrderAlertData: (state, actions) => {
      state.orderAlertData = actions.payload;
    },
    saveStrategyInfo: (state, actions) => {
      state.strategyInfoData = actions.payload;
    },
  },
});

export const {
  SetLoading,
  brokerlistdata,
  SetEmptycsv,
  profiledata,
  instruments,
  zerodhaAccsess,
  csvDownload,
  csvName,
  saveMarketData,
  saveOrderAlertData,
  saveStrategyInfo,
} = globalSlice.actions;

export default globalSlice.reducer;

export function LoginUser({ setShow, loginData, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(Base_url + "customer-login", loginData);
      localStorage.setItem("email", loginData.email);
      if (response?.data?.status === 200) {
        dispatch(SetLoading(false));
        setShow(true);
        toast.success(response?.data?.message, { autoClose: 1000 });
        // navigate("/verify-otp");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}

export function RegisterUser({ setShow, registerData, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        Base_url + "customer-register",
        registerData
      );
      localStorage.setItem("email", registerData.email);
      if (response?.data?.status === 200) {
        setShow(true);
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        // navigate("/verify-otp");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function googlelogin({ logindata, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(Base_url + "google-login", logindata);
      if (response?.data) {
        dispatch(SetLoading(false));
        localStorage.setItem("Token", response?.data?.token);
        localStorage.setItem("user_id", response?.data?.user_id);
        toast.success(response?.data?.message);
        navigate("/");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function Otpverify({ obj, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(Base_url + "verify-otp", obj);
      if (response?.data) {
        localStorage.setItem("Token", response?.data?.token);
        dispatch(SetLoading(false));
        // toast.success(response?.data?.message, { autoClose: 1000 });
        navigate("/");
        toast.success(
          "Please generate Access Token to continue running live strategies and downloading historical data",
          { autoClose: 4000 }
        );
        localStorage.removeItem("email");
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function usersettingsget(dispatch) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        Base_url + "user-settings",
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(brokerlistdata(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}

export function usersettingspost({ formDataa, dispatch, navigate }) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        Base_url + "user-settings",
        formDataa,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        dispatch(usersettingsget(dispatch));
        navigate("/settings");
        window.location.reload();
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function profileget(dispatch) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        Base_url + "profile-update",
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(profiledata(response?.data));
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function profilepost({ updatedata, dispatch }) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        Base_url + "profile-update",
        updatedata,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function instrumentsdetails(dispatch) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        Base_url + "instruments-lists",
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(instruments(response?.data));
        return response?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function historicaldata({ historydata, setHistorydata, dispatch }) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        Base_url + "historical-data-setting",
        historydata,
        requestOptions
      );
      console.log("response", response);
      if (response?.data.status === 200 || response?.data.status === 202) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.data?.message);
        dispatch(csvDownload(response?.data?.data?.file_data));
        dispatch(csvName(response?.data?.data?.file_name));
        setHistorydata({
          broker_name: "",
          name: "",
          instrument_token: "",
          from_date: null,
          to_date: null,
          trading_symbol: "",
          interval_time: "",
          exchange: "",
          daily_basis_update: false,
        });
        return response?.data;
      } else {
        toast.error(response?.data?.message);
        dispatch(SetLoading(false));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function zerodhasuccessToken({ zerodhaData, dispatch }) {
  return async () => {
    const token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const api_secret = zerodhaData?.api_secret;
    const requestToken_token = zerodhaData?.request_token;
    try {
      dispatch(SetLoading(true));
      if (api_secret && requestToken_token) {
        const response = await axios.post(
          `${Base_url}redirect-request-token?request_token=${requestToken_token}&api_secret=${api_secret}`,
          {},
          requestOptions
        );
        if (response?.data.status === 200 || response?.data.status === 202) {
          dispatch(SetLoading(false));
          dispatch(zerodhaAccsess(response?.data?.access_token));
          toast.success(response?.data?.message);
          return response?.data;
        } else {
          toast.error(response?.data?.message);
          dispatch(SetLoading(false));
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function getMarketDataUrl(dispatch) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${Base_url}market-data`,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(saveMarketData(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}

export function getOrderAlert(dispatch) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${Base_url}place-order-alerts`,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(saveOrderAlertData(response?.data?.order_alerts));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}
export function getStrategyInfo(dispatch) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.get(
        `${Base_url}strategy-info`,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(saveStrategyInfo(response?.data?.strategies));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}
export function postStrategyInfo({ dispatch, updatedData }) {
  return async () => {
    let token = localStorage.getItem("Token");
    const requestOptions = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    try {
      dispatch(SetLoading(true));
      const response = await axios.post(
        `${Base_url}strategy-info`,
        updatedData,
        requestOptions
      );
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(getStrategyInfo(dispatch))
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
    }
  };
}
