import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { RegisterUser } from "../../redux/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import VerifyOTP from '../Authentication/VerifyOTP';
import { useDispatch } from 'react-redux';

const Regform = () => {

  const [error, setErrors] = useState({});
  const [nameerror, setNameError] = useState("");
  const [lasterror, setLastError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);


  const HandleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'first_name') {
      const isValid = /^[a-zA-Z0-9 ]*$/.test(value);
      if (!isValid) {
        setNameError('Special characters not allowed');
        return;
      } else {
        setNameError('');
      }
    } else if (name === 'last_name') {
      const isValid = /^[a-zA-Z0-9 ]*$/.test(value);
      if (!isValid) {
        setLastError('Special characters not allowed');
        return;
      } else {
        setLastError('');
      }
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: registerData.password !== value ? "Passwords do not match" : undefined,
      }));
    } else {
      setRegisterData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value.trim(),
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };


  const Validation = () => {
    const errors = {};
    if (registerData?.first_name === "" && registerData?.first_name?.length === 0) {
      errors.first_name = "Enter First Name*";
    }
    if (registerData?.last_name === "" && registerData?.last_name?.length === 0) {
      errors.last_name = "Enter Last Name*";
    }
    if (registerData?.email === "" && registerData?.email?.length === 0) {
      errors.email = "Enter Email*";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(registerData?.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (registerData?.password === "" && registerData?.password?.length === 0) {
      errors.password = "Enter Password*";
    }
    if (confirmPassword === "" && confirmPassword?.length === 0) {
      errors.confirmPassword = "Enter Confirm Password*";
    } else if (registerData.password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const HandleSignUp = (e) => {
    e?.preventDefault();
    let data = Validation();
    if (data) {
      return;
    } else {
      dispatch(RegisterUser({ setShow, registerData, navigate, dispatch }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  return (
    <>
      <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
        <div
          id="radius-shape-1"
          className="position-absolute rounded-circle shadow-5-strong"
        ></div>
        <div
          id="radius-shape-2"
          className="position-absolute shadow-5-strong"
        ></div>

        <div className="card bg-glass">
          <div className="card-body px-4 py-3 px-md-5">
            <div>
              <div className="form-outline mb-4 Label_css">
                <label className="form-label" htmlFor="form3Example3">
                  <p style={{ fontSize: "30px" }}>Register</p>
                </label>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={registerData.first_name}
                  className="form-control"
                  onChange={(e) => HandleChange(e)}
                />
                <p style={{ color: "red" }}>{error.first_name}</p>
                <p style={{ color: "red" }}>{nameerror}</p>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  className="form-control"
                  value={registerData.last_name}
                  onChange={(e) => HandleChange(e)}
                />
                <p style={{ color: "red" }}>{error.last_name}</p>
                <p style={{ color: "red" }}>{lasterror}</p>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Email address
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={registerData.email}
                  onChange={(e) => HandleChange(e)}
                />
                <p style={{ color: "red" }}>{error.email}</p>
              </div>

              <div className="form-outline mb-4" style={{ position: "relative" }}>
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control"
                  onChange={(e) => HandleChange(e)}
                />
                <div className="pass-toggle-reg">
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={togglePasswordVisibility} />
                </div>
                <p style={{ color: "red" }}>{error.password}</p>
              </div>
              <div className="form-outline mb-4" style={{ position: "relative" }}>
                <label className="form-label" htmlFor="form3Example5">
                  Confirm Password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  className="form-control"
                  onChange={(e) => HandleChange(e)}
                />
                <div className="pass-toggle-reg">
                  <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility} />
                </div>
                <p style={{ color: "red" }}>{error.confirmPassword}</p>
              </div>


              <button
                type="submit"
                className="btn btn-primary btn-block mb-4 btn-100"
                onClick={(e) => HandleSignUp(e)}
              >
                Sign up
              </button>
              <div className="text-center">
                <p>
                  Already have an account ? <Link to="/login" style={{ textDecoration: "none" }}>Sign in</Link>
                </p>
                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="auth-modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>{<VerifyOTP />}</Modal.Body>
      </Modal>
    </>
  )
}

export default Regform