import React from 'react'
import Historydata from '../../Components/Component/Historydata'

const History = () => {
    return (
        <>
            <div>
                <Historydata />
            </div>
        </>
    )
}

export default History