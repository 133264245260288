import React, { useEffect } from "react";
import "../../App.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMarketDataUrl } from "../../redux/auth/authSlice";

const Sidebar = ({ HandleToggle }) => {
  const pathName = useLocation();
  const dispatch = useDispatch();
  const { marketData } = useSelector((state) => state?.counter);

  useEffect(() => {
    dispatch(getMarketDataUrl(dispatch));
  }, [dispatch]);

  return (
    <>
      <div className="side-bar">
        <div className="abs-bk">
          <i className="fa-solid fa-caret-left" onClick={HandleToggle}></i>
        </div>
        <div className="logo-sec">
          <Link to="/"
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "auto",
              fontWeight: "600",
              padding: "30px",
              fontSize: "40px",
              textDecoration: "none"
            }}
          >
            Q-Star
          </Link>
        </div>
        <div className="link-div">
          <ul className="link-ul">
            <li>
              <Link
                to="/"
                className={pathName.pathname === "/" ? "active" : ""}
              >
                <i className="fa-solid fa-house"></i>Home
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className={pathName.pathname === "/profile" ? "active" : ""}
              >
                <i className="fa-solid fa-user"></i>Profile
              </Link>
            </li>
            {/* <li>
              <Link
                to="/holdings"
                className={pathName.pathname === "/holdings" ? "active" : ""}
              >
                <i class="fa-solid fa-hands-holding"></i>Holdings
              </Link>
            </li> */}
            <li >
              <Link
                to="/"
                className={pathName.pathname === "/historicaldata" ? "active" : ""}
                onClick={() => window.open(marketData?.nb_url, "_blank")}
              >
                <i className="fa-solid fa-money-bill-trend-up"></i>Historical Data
              </Link>
            </li>
            <li>
              <Link
                to="/history"
                className={pathName.pathname === "/history" ? "active" : ""}
              >
                <i className="fa-solid fa-clock-rotate-left"></i>Download Historical Data
              </Link>
            </li>
            <li>
              <Link
                to="/alerts"
                className={pathName.pathname === "/alerts" ? "active" : ""}
              >
                <i className="fa-solid fa-circle-exclamation"></i>Alerts
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                className={pathName.pathname === "/settings" ? "active" : ""}
              >
                <i className="fa-solid fa-gear"></i>Settings
              </Link>
            </li>
            <li></li>
          </ul>
        </div>
      </div>

      {/* mobile view */}
      <div className="sidebar-mobile">
        <div className="side-bar-mob" onClick={HandleToggle}>
          <div className="abs-bk">
            <i className="fa-solid fa-caret-left" onClick={HandleToggle}></i>
          </div>
          <div className="logo-sec">
            <Link to="/"
              style={{
                color: "white",
                textAlign: "center",
                fontFamily: "auto",
                fontWeight: "600",
                padding: "30px",
                fontSize: "40px",
                textDecoration: "none"
              }}
            >
              Q-Star
            </Link>
          </div>
          <div className="link-div">
            <ul className="link-ul">
              <li>
                <Link
                  to="/"
                  className={pathName.pathname === "/" ? "active" : ""}
                >
                  <i className="fa-solid fa-house"></i>Home
                </Link>
              </li>
              <li>
                <Link
                  to="/profile"
                  className={pathName.pathname === "/profile" ? "active" : ""}
                >
                  <i className="fa-solid fa-user"></i>Profile
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/holdings"
                  className={pathName.pathname === "/holdings" ? "active" : ""}
                >
                  <i class="fa-solid fa-hands-holding"></i>Holdings
                </Link>
              </li> */}
              <li>
                <Link
                  to="/"
                  onClick={() => window.open(marketData?.nb_url, "_blank")}
                  className={pathName.pathname === "/historicaldata" ? "active" : ""}
                >
                  <i className="fa-solid fa-money-bill-trend-up"></i>HistoricalData
                </Link>
              </li>
              <li>
                <Link
                  to="/history"
                  className={pathName.pathname === "/history" ? "active" : ""}
                >
                  <i className="fa-solid fa-clock-rotate-left"></i>History
                </Link>
              </li>
              <li>
                <Link
                  to="/alerts"
                  className={pathName.pathname === "/alerts" ? "active" : ""}
                >
                  <i className="fa-solid fa-circle-exclamation"></i>Alerts
                </Link>
              </li>
              <li>
                <Link
                  to="/settings"
                  className={pathName.pathname === "/settings" ? "active" : ""}
                >
                  <i className="fa-solid fa-gear"></i>Settings
                </Link>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
