import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const Zerodhasuccess = () => {
    const Base_url = "https://api.qstar-web.com/api/"

    const location = useLocation();
    const navigate = useNavigate();
    const [zerodhaData, setZerodhaData] = useState({
        request_token: ''
    })

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const requestToken = params.get('request_token');
        if (requestToken) {
            setZerodhaData({
                request_token: requestToken
            })
        }
    }, [location.search]);

    useEffect(() => {
        const zerodhaAccess = async () => {
            const token = localStorage.getItem("Token")
            const requestOptions = {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
            const requestToken_token = zerodhaData?.request_token
            try {
                // dispatch(SetLoading(true));
                if (requestToken_token) {
                    const response = await axios.post(`${Base_url}redirect-request-token?request_token=${requestToken_token}`, {}, requestOptions);
                    if (response?.data.status === 200 || response?.data.status === 202) {
                        // dispatch(SetLoading(false));
                        // dispatch(zerodhaAccsess(response?.data?.access_token));
                        toast.success(response?.data?.message);
                        setTimeout(() => {
                            navigate("/")
                        }, 4000)
                    }
                    else {
                        toast.error(response?.data?.message);
                        // dispatch(SetLoading(false));
                    }
                }
            } catch (error) {
                toast.error(error?.response?.data?.message);
                // dispatch(SetLoading(false));
            }
        };
        zerodhaAccess();
    }, [zerodhaData, navigate]);

    return (
        <div className="main-container">
            <div className="check-container">
                <div className="check-background">
                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="check-shadow"></div>
            </div>
            <div>Now you are redirecting to homepage</div>
        </div>
    )
}

export default Zerodhasuccess;
