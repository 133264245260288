import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { usersettingsget, usersettingspost } from '../../redux/auth/authSlice';
import Loader from "../../Components/Loader/Loader";
// import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';



const SettingsItem = ({ index, item }) => {

  const dispatch = useDispatch();
  const [id, setId] = useState(null)
  const [formDataa, setFormDataa] = useState({
    api_secret: '',
    api_key: '',
    broker_name: '',
    broker_user_id: '',
  });


  const handleChange = (e, index) => {
    setId(index)
    setFormDataa({
      ...formDataa,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleUpdate = (val) => {
    if (id === val) {
      // onUpdate(formDataa);
      dispatch(usersettingspost({ formDataa, dispatch }))
    }
  };

  useEffect(() => {
    setFormDataa({
      api_secret: item?.api_secret,
      api_key: item?.api_key,
      broker_name: item?.broker_name,
      broker_user_id: item?.broker_user_id,
    });
  }, [item]);

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);


  const copyData = (ref) => {
    if (ref.current) {
      ref.current.select();
      document.execCommand('copy');
      toast.success("Copied")
    }
  };

  const maskString = (str) => {
    return str.length <= 4 ? str : '*'.repeat(str.length - 4) + str.slice(-4);
  };


  return (
    <>
      <div>


        <div className="container">
          <div className="card col-lg-6">
            <div className="title">
              <h1 style={{ color: "#2f4d81", fontWeight: 400, fontSize: "25px" }}>Broker Name</h1>
              <h2 style={{ color: "#2f4d81", fontWeight: 600, fontSize: "25px" }}>{item.broker_name.replace(/^\w/, (c) => c.toUpperCase())}</h2>
            </div>
            <div className="content">
              <div className='secretKey-div' key={index}>
                <label htmlFor="secretAccessKey" style={{ marginRight: "5px", color: "#2f4d81" }}>Broker Id:</label>
                <input ref={inputRef3} type="text" id="secretAccessKey" name="broker_user_id" value={maskString(formDataa.broker_user_id)} onChange={(e) => handleChange(e, index)} />
                {/* <button className="copybtn" onClick={() => copyData(inputRef3)}><i className="fa-regular fa-copy"></i></button> */}
              </div>

              <div className='secretKey-div' key={index}>
                <label htmlFor="secretAccessKey" style={{ marginRight: "5px", color: "#2f4d81" }}>Api Key:</label>
                <input ref={inputRef2} type="text" id="secretAccessKey" name="api_key" value={maskString(formDataa?.api_key)} onChange={(e) => handleChange(e, index)} />
                {/* <button className="copybtn" onClick={() => copyData(inputRef2)}><i className="fa-regular fa-copy"></i></button> */}
              </div>
              <div className='secretKey-div' key={index}>
                <label htmlFor="secretKey" style={{ marginRight: "5px", color: "#2f4d81" }}>Secret Key:</label>
                <input ref={inputRef} type="text" id="secretKey" name="api_secret" value={maskString(formDataa.api_secret)} onChange={(e) => handleChange(e, index)} />
                {/* <button className='copybtn' onClick={() => copyData(inputRef)}><i className="fa-regular fa-copy"></i></button> */}
              </div>



              <div className='secretKey-div div_hover_link' key={index}>
                {/* <label htmlFor="secretAccessKey" style={{ marginRight: "5px", color: "#2f4d81" }}></label> */}
                <a htmlFor="secretAccessKey" href={item?.api_url} style={{ marginRight: "5px", color: "#2f4d81" }}>Click here to Generate Request Token</a>
              </div>


              <button className='add-key-btn' onClick={() => handleUpdate(index)}>Update</button>

            </div>
            <div className="circle"></div>
          </div>
        </div>

      </div>

    </>



  );
};

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usersettingsdata, isLoading } = useSelector((state) => state?.counter);
  const [linl, setLin1] = useState("");
  const [brokid, setBrokid] = useState("");

  // const linl = usersettingsdata?.data[0].api_key || undefined;
  // const brokid = usersettingsdata?.data
  // console.log("linl", linl, "brokid", brokid)

  // const linl = usersettingsdata?.data[0].api_key;


  useEffect(() => {
    if (usersettingsdata?.data && usersettingsdata.data.length >= 0) {
      setLin1(usersettingsdata.data[0]?.api_key)
      setBrokid(usersettingsdata.data[0]?.broker_user_id)
    } else {
      console.log("usersettingsdata or usersettingsdata.data is undefined or empty");
    }
  }, [usersettingsdata])






  // const linkhandle = () => {
  //   https://kite.zerodha.com/connect/login?api_key=wwdwddscas&v=3&redirect_params=user_id=wqd
  // }
  const datalength = usersettingsdata?.data?.length > 1;

  const handleclick = () => {
    let token = localStorage.getItem("Token");
    if (token) {
      navigate("/addkey");
    } else {
      navigate("/login");
    }
  };

  // const handleUpdate = (index, newData) => {
  //   console.log("Updating item at index", index, "with data:", newData);
  // };

  useEffect(() => {
    dispatch(usersettingsget(dispatch));
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader />}

      <div>
        <button className='add-key-btn' onClick={handleclick}>Add New Key</button>
      </div>

      {usersettingsdata?.data?.length > 0 ? (
        <div className='data-main-div'>
          <h4 className='form-headings'>
            Broker List
          </h4>
          <div className='row card-data-1'>
            {usersettingsdata?.data?.map((item, index) => (

              <div className={datalength ? 'col-lg-6 mb-3 setting-item-class' : " col-lg-12 mb-3 setting-item-class"} ><SettingsItem key={index} index={index} item={item} /></div>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }} >
          <h1>No Data</h1>
        </div>
      )}
    </>
  );
};



export default Settings;