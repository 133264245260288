import React, { useEffect, useState, } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStrategyInfo, postStrategyInfo, usersettingsget } from "../../redux/auth/authSlice";
import Loader from "../../Components/Loader/Loader";



const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Add pagination state
  const itemsPerPage = 10; // Items per page
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10); // Show only 10 page numbers at a time
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const { usersettingsdata, strategyInfoData, isLoading } = useSelector((state) => state?.counter);
  const linkdata = usersettingsdata?.data?.map((item) => item.api_url);

  useEffect(() => {
    let token = localStorage.getItem("Token");
    if (token) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(usersettingsget(dispatch));
    dispatch(getStrategyInfo(dispatch));
  }, [dispatch]);

  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAlerts = strategyInfoData?.filter((alert) => {
    const isStatusMatch =
      statusFilter === "all" ||
      (statusFilter === "active" && alert.status === "active") ||
      (statusFilter === "pause" && alert.status !== "active");

    const isSearchMatch = () => {
      return (
        alert.id.toString().includes(searchQuery) ||
        alert.broker_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        alert.trading_symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
        alert.strategy.toLowerCase().includes(searchQuery.toLowerCase()) ||
        alert.exchange.toLowerCase().includes(searchQuery.toLowerCase()) ||
        alert.transaction_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        alert.type.toLowerCase().includes(searchQuery.toLowerCase())
      );
    };
    return isStatusMatch && isSearchMatch();
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAlerts?.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + 10);
      setMinPageNumberLimit(minPageNumberLimit + 10);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % 10 === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - 10);
      setMinPageNumberLimit(minPageNumberLimit - 10);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredAlerts?.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleStatusChange = (id, newStatus) => {
    const updatedData = {
      id: id,
      status: newStatus
    };
    dispatch(postStrategyInfo({ dispatch, updatedData }));
  };

  return (
    <>
      <div className="mt-5 mb-3 ms-4">
        <h4 className="dash-head">
          Please generate Access Token to continue running live strategies and downloading historical data
        </h4>
        <div></div>
        {usersettingsdata?.data?.length > 0 ? (
          <div>
            <a href={linkdata} style={{ marginRight: "5px", color: "#2f4d81" }}>
              Click here to Generate Access Token
            </a>
          </div>
        ) : (
          <div>
            <a href="/settings" style={{ marginRight: "5px", color: "#2f4d81" }}>
              Click here to Generate Access Token
            </a>
          </div>
        )}
      </div>

      <div className="strategy-info-container ms-3">
        <div className="strategy-info-header">
          <h1 className="strategy-info-title">Strategy Info</h1>
          <div className="strategy-info-controls">
            <select value={statusFilter} onChange={handleFilterChange} className="strategy-info-select">
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="pause">Pause</option>
            </select>
            <input
              type="text"
              placeholder="Search by ID, Trading Symbol, Broker Name, or Date"
              value={searchQuery}
              onChange={handleSearchChange}
              className="strategy-info-search"
            />
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : currentItems?.length === 0 ? (
          <table className="strategy-info-table">
            <thead>
              <tr>
                <th>Strategy</th>
                <th>Broker Name</th>
                <th>Trading Symbol</th>
                <th>Quantity</th>
                <th>Product</th>
                <th>Order Type</th>
                <th>Trigger Price</th>
                <th>Transaction Type</th>
                <th>Exchange</th>
                <th>Variety</th>
                <th>Instrument Token</th>
                <th>Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  No data available
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="strategy-info-table-wrapper">
            <table className="strategy-info-table">
              <thead>
                <tr>
                  <th>Strategy</th>
                  <th>Broker Name</th>
                  <th>Trading Symbol</th>
                  <th>Quantity</th>
                  <th>Product</th>
                  <th>Order Type</th>
                  <th>Trigger Price</th>
                  <th>Transaction Type</th>
                  <th>Exchange</th>
                  <th>Variety</th>
                  <th>Instrument Token</th>
                  <th>Trade type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((alert) => (
                  <tr key={alert?.id}>
                    <td>{alert?.strategy}</td>
                    <td>{alert?.broker_name}</td>
                    <td>{alert?.trading_symbol}</td>
                    <td>{alert?.quantity}</td>
                    <td>{alert?.product}</td>
                    <td>{alert?.order_type}</td>
                    <td>{alert?.trigger_price}</td>
                    <td>{alert?.transaction_type}</td>
                    <td>{alert?.exchange}</td>
                    <td>{alert?.variety}</td>
                    <td>{alert?.instrument_token}</td>
                    <td>{alert?.type}</td>
                    <td>
                      <select
                        value={alert?.status}
                        defaultValue={alert?.status}
                        onChange={(e) => handleStatusChange(alert?.id, e.target.value)}
                        className="status-select"
                      >
                        <option value="active">Active</option>
                        <option value="pause">Pause</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="pagination">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="pagination-btn"
          >
            « Prev
          </button>
          {pages
            .filter((page) => page <= maxPageNumberLimit && page > minPageNumberLimit)
            .map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                className={`pagination-btn ${currentPage === page ? "active" : ""}`}
              >
                {page}
              </button>
            ))}
          <button
            onClick={handleNextPage}
            disabled={currentPage === pages.length}
            className="pagination-btn"
          >
            Next »
          </button>
        </div>

      </div>
    </>
  );
};


export default Dashboard;
